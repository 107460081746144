<template>
	<el-row style="height: calc(100% - 60px)">
		<el-col :span="24"> <ChatComponent ref="childChat" /></el-col>
	</el-row>
</template>

<script>
import ChatComponent from "../ChargeMeEnterpriseGPT/ResponseLineBot/Chat.vue";

export default {
	name: "ResponseLineBot",
	data() {
		return {
			activeMenu: "1",
			currentComponent: "ChatComponent", // 默認顯示的組件
		};
	},
	components: {
		ChatComponent,
	},
	watch: {
		activeMenu(newVal) {
			// 這裡可以根據需要添加其他的邏輯
			console.log("Active tab changed to:", newVal);
		},
		$route(to) {
			this.updateCurrentComponent(to.name); // 當路由改變時更新子組件
		},
	},
	computed: {
		tabMargin() {
			return this.activeName === "note" && !this.$store.getters.mobileSize;
		},
	},
	methods: {
		childChatInit() {
			this.$refs.childChat.loadData();
		},
		updateCurrentComponent(routeName) {
			if (routeName.includes("Chat")) {
				this.currentComponent = "ChatComponent"; // 替換為實際的子組件名稱
			} else if (routeName.includes("Contact")) {
				this.currentComponent = "ContactComponent"; // 替換為實際的子組件名稱
			} else if (routeName.includes("FilterMessage")) {
				this.currentComponent = "FilterMessageComponent"; // 替換為實際的子組件名稱
			} else {
				this.currentComponent = "ChatComponent"; // 替換為默認子組件名稱
			}
		},
		handleMenuSelect(index) {
			this.activeMenu = index;
			switch (index) {
				case "1":
					this.currentComponent = "ChatComponent";
					this.$router.push({ name: "ResponseLineBotGPTChat" }); // 假設路由為 /chat
					break;
				case "2":
					this.currentComponent = "ContactComponent";
					this.$router.push({ name: "ResponseLineBotGPTContact" }); // 假設路由為 /contact
					break;
				case "3":
					this.currentComponent = "FilterMessageComponent";
					this.$router.push({ name: "ResponseLineBotGPTFilterMessage" }); // 假設路由為 /filter
					break;
				default:
					this.currentComponent = "ChatComponent"; // 默認返回聊天
			}
		},
	},
	async mounted() {
		const path = this.$route.path;
		if (path.includes("Chat")) {
			this.activeMenu = "1";
		} else if (path.includes("Contact")) {
			this.activeMenu = "2";
		} else if (path.includes("FilterMessage")) {
			this.activeMenu = "3";
		} else {
			this.activeMenu = "1"; // 默認為聊天
		}
	},
};
</script>
