<template>
  <el-tabs class="sticky-tabs" type="card" v-model="activeName" :class="{ tabMargin: tabMargin }">
    <el-tab-pane name="note">
      <template v-slot:label>編輯資料</template>
      <Note ref="childNote" />
    </el-tab-pane>
    <el-tab-pane name="note1" label="prompt編輯頁">
      <div class="d-flex">
        <div class="p-2 mx-auto" style="max-width: 500px">
          <el-input placeholder="營區名稱" v-model="promptData.user_name" />
          <div class="mt-2 text-start">
            <el-switch style="display: block" v-model="promptData.isEnbaled" active-color="#13ce66" inactive-color="#ff4949" active-text="啟用" inactive-text="停用"> </el-switch>
          </div>

          <el-input type="textarea" :autosize="{ minRows: 4 }" placeholder="基本提示詞" v-model="promptData.promptDesc2"> </el-input>
          <el-input class="mt-2" type="textarea" :autosize="{ minRows: 4 }" placeholder="分類提示詞" v-model="promptData.promptDesc3"> </el-input>
          <div class="row my-3 text-start">
            <!-- <div class="col-4"><span>任務名稱</span></div>
					<div class="col-8"><span>任務描述</span></div> -->

            <!-- task1 -->
            <div class="col-12 mt-2">
              <el-input placeholder="任務名稱1" v-model="promptData.task1.desc" class="mb-2"> </el-input>
              <el-input type="textarea" placeholder="任務範圍描述" v-model="promptData.task1.tipWord" :autosize="{ minRows: 4 }"> </el-input>
            </div>

            <!-- task2 -->
            <div class="col-12 mt-2">
              <el-input placeholder="任務名稱2" v-model="promptData.task2.desc" class="mb-2"> </el-input>
              <el-input type="textarea" placeholder="任務範圍描述" v-model="promptData.task2.tipWord" :autosize="{ minRows: 4 }"> </el-input>
            </div>

            <!-- task3 -->
            <div class="col-12 mt-2">
              <el-input placeholder="任務名稱3" v-model="promptData.task3.desc" class="mb-2"> </el-input>
              <el-input type="textarea" placeholder="任務範圍描述" v-model="promptData.task3.tipWord" :autosize="{ minRows: 4 }"> </el-input>
            </div>

            <!-- task4 -->
            <div class="col-12 mt-2">
              <el-input placeholder="任務名稱4" v-model="promptData.task4.desc" class="mb-2"> </el-input>
              <el-input type="textarea" placeholder="任務範圍描述" v-model="promptData.task4.tipWord" :autosize="{ minRows: 4 }"> </el-input>
            </div>

            <!-- task5 -->
            <div class="col-12 mt-2">
              <el-input placeholder="任務名稱5" v-model="promptData.task5.desc" class="mb-2"> </el-input>
              <el-input type="textarea" placeholder="任務範圍描述" v-model="promptData.task5.tipWord" :autosize="{ minRows: 4 }"> </el-input>
            </div>
          </div>
          <div class="text-end">
            <el-button type="primary" plain round @click="getData()">取消(復原)</el-button>
            <el-button type="success" plain round @click="saveData()">存檔</el-button>
          </div>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane name="note2" label="回覆Line bot">
      <template v-slot:label> 回覆Line bot</template>
      <ResponseLineBot ref="childResponseLineBot" />
    </el-tab-pane>
    <!-- <el-tab-pane name="note3" label="QA對話查詢">QA對話查詢</el-tab-pane> -->
  </el-tabs>
</template>

<script>
import Note from "../ChargeMeContactBook/Note/Note.vue";
import ResponseLineBot from "../../components/ChargeMeEnterpriseGPT/ResponseLineBot.vue";
export default {
  name: "Home",
  data() {
    return {
      activeName: "note",
      textarea2: "",
      promptData: {
        isEnbaled: false,
        task1: {
          desc: "",
          id: "1",
          name: "",
          tipWord: "",
        },
        task2: {
          desc: "",
          id: "2",
          name: "",
          tipWord: "",
        },
        task3: {
          desc: "",
          id: "3",
          name: "",
          tipWord: "",
        },
        task4: {
          desc: "",
          id: "4",
          name: "",
          tipWord: "",
        },
        task5: {
          desc: "",
          id: "5",
          name: "",
          tipWord: "",
        },
        promptDesc2: "",
        promptDesc3: "",
        promptId: "",
        user_id: "",
        user_name: "",
      },
    };
  },
  methods: {
    callChildMethod() {
      this.$refs.childNote.initNoteData();
    },
    callChildMethod_childResponseLineBot() {
      this.$refs.childResponseLineBot.initNoteData();
    },
    async saveData() {
      let vm = this;
      let promptDataCopy = JSON.parse(JSON.stringify(vm.promptData));
      Object.keys(promptDataCopy).forEach(key => {
        if (key.startsWith("task")) {
          promptDataCopy[key].name = promptDataCopy.user_name;
          promptDataCopy[key].id = key.replace("task", "");
        }
      });
      await vm.fetchAPI("post", `${process.env.VUE_APP_URL_API}/GPTPrompt/Save`, promptDataCopy).then(res => {
        console.log(res);
        vm.notify("success", `設定成功！`);
        vm.getData();
      });
    },
    async getData() {
      let vm = this;
      await vm.fetchAPI("get", `${process.env.VUE_APP_URL_API}/GPTPrompt/Get`).then(res => {
        console.log(res);
        vm.promptData = res.data.response;
      });
    },
  },
  components: {
    ResponseLineBot,
    Note,
  },
  watch: {
    async activeName(newVal) {
      if (newVal == "note2") {
        this.$router.push({ name: "ResponseLineBotGPT" });
        await this.$refs.childResponseLineBot.childChatInit();
      } else if (newVal == "note1") {
        await this.getData();
      } else {
        this.callChildMethod();
        this.$router.push({ name: "EnterpriseGPTHome" });
      }
    },
  },
  computed: {
    tabMargin() {
      return this.activeName === "note" && !this.$store.getters.mobileSize;
    },
  },
  async mounted() {
    const path = this.$route.path;
    if (path.includes("ResponseLineBot")) {
      this.activeName = "note2";
    }
  },
};
</script>

<style lang="scss" scoped>
.tabMargin {
  margin-left: 250px;
}
::v-deep .el-tabs__header {
  margin: 0 !important;
}
</style>
